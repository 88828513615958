import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const registerExternalPayment = createAsyncThunk(
  "externalPayment/register",
  async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(
      "register_external_payment",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }
);

export const verifyExternalPayment = createAsyncThunk(
  "externalPayment/verify",
  async ({file, provider}, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("provider", provider);

      const response = await apiClient.post(
        "/verify_external_payment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendExternalPaymentData = createAsyncThunk(
  "externalPayment/send",
  async ({ uuid_user, uuid_branch, entidadRecaudadora, puntoDeVenta, electronicInvoice, fechaFactura, file, provider }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("user_uuid", uuid_user);
      formData.append("branch_uuid", uuid_branch);
      formData.append("file", file);
      formData.append("legal_entity_uuid", entidadRecaudadora);
      formData.append("sale_point_uuid", puntoDeVenta);
      formData.append("entry_date", fechaFactura);
      formData.append("electronic_invoice", !electronicInvoice);
      formData.append("provider", provider);

      const response = await apiClient.post(
        "register_external_payment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue({ message: "Error en el envío (simulado)" });
    }
  }
);
import React, { useState, useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  TextField 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  sendExternalPaymentData,
  verifyExternalPayment,
} from "../api/endpoints/externalPayment";

const CouponsDialog = ({ open, onClose, coupons, lastLineData, onConfirm }) => {
  const [entidadRecaudadora, setEntidadRecaudadora] = useState("");
  const [puntoDeVenta, setPuntoDeVenta] = useState("");
  const [electronicInvoice, setElectronicInvoice] = useState(false);
  const [fechaFactura, setFechaFactura] = useState(() => {
    // Establecer fecha por defecto (hoy)
    const hoy = new Date();
    return hoy.toISOString().substring(0, 10); // Convertir a formato 'YYYY-MM-DD'
  });
  const resetForm = () => {
    setEntidadRecaudadora("");
    setPuntoDeVenta("");
    setElectronicInvoice(false);
  };

  // Estilos para el header de las tablas y las celdas con fondo gris
  const headerCellStyle = {
    fontWeight: "bold",
    backgroundColor: "#ccc",
    textAlign: "center",
  };
  const greyCellStyle = { backgroundColor: "#f2f2f2" };
  const redTextStyle = { color: "red", fontWeight: "bold" };
  const sectionStyle = { marginBottom: "16px" };

  // Información adicional hardcodeada para los selects
  const entidadesRec = useSelector(
    (state) => state.legalEntity.recLegalEntities
  );
  const { salePoints } = useSelector((state) => state.salePoints);
  const caeDate = "27/12/2023"; // Fecha CAE hardcodeada
  const [showCae, setShowCae] = useState("");

  // Función para manejar el cambio de CAE
  const handleCaeChange = (event) => {
    setShowCae(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Cupones a procesar: {lastLineData.cantCupones}{" "}
        <span style={{ fontWeight: "normal" }}>
          (Archivo: {lastLineData.fileName})
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={sectionStyle}>
          {/* Header con los montos de TXT y DB */}
          <Grid item xs={6} style={headerCellStyle}>
            Archivo txt: ${lastLineData.importeTotalTxt}
          </Grid>
          <Grid item xs={6} style={headerCellStyle}>
            Cupones Internos: ${lastLineData.importeTotalDb}
          </Grid>
        </Grid>

        {/* Mapeo de los cupones */}
        {coupons.map((coupon, index) => (
          <Grid container key={index} spacing={2} style={sectionStyle}>
            {/* Columna Izquierda */}
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>
                Num.Orden: {parseInt(coupon.orderNumber, 10).toString()}
              </Typography>
              <Typography>Fecha de pago: {coupon.paymentDate}</Typography>
              <Typography>Importe: ${coupon.amount}</Typography>
            </Grid>
            {/* Columna Derecha */}
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>
                Mes: {coupon.month} - {coupon.year}, Cuota/s: {coupon.feeNumber}
              </Typography>
              <Typography style={greyCellStyle}>
                Importe 1er Venc: ${coupon.firstDueAmount}, Fecha 1er Venc:{" "}
                {coupon.firstDueDate}
              </Typography>
              <Typography>
                Importe 2do Venc: ${coupon.secondDueAmount}, Fecha 2do Venc:{" "}
                {coupon.secondDueDate}
              </Typography>
              <Typography>
                Importe 3er Venc: ${coupon.thirdDueAmount}, Fecha 3er Venc:{" "}
                {coupon.thirdDueDate}
              </Typography>
            </Grid>
          </Grid>
        ))}

        {/* Selects de Entidad Recaudadora y Punto de Venta */}
        <Grid container spacing={2} style={sectionStyle}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Entidad Recaudadora</InputLabel>
              <Select
                defaultValue=""
                value={entidadRecaudadora}
                onChange={(e) => setEntidadRecaudadora(e.target.value)}
              >
                {entidadesRec.map((option) => (
                  <MenuItem key={option.uuid} value={option.uuid}>
                    {option.company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Punto de venta</InputLabel>
              <Select
                defaultValue=""
                value={puntoDeVenta}
                onChange={(e) => setPuntoDeVenta(e.target.value)}
              >
                {salePoints.map((option) => (
                  <MenuItem key={option.uuid} value={option.uuid}>
                    {option.number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Fecha factura"
                type="date"
                value={fechaFactura}
                onChange={(e) => setFechaFactura(e.target.value)}
                InputLabelProps={{
                  shrink: true, // Hace que el label siempre esté encima
                }}
              />
            </FormControl>
          </Grid>
          {/* Mensaje de CAE y Checkbox */}

          <Grid item xs={4}>
            <Typography style={redTextStyle}>
              Fecha Última factura CAE: {caeDate}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox checked={electronicInvoice}
                  onChange={(e) => setElectronicInvoice(e.target.checked)}
                />
              }
              label="No generar CAE"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onConfirm(entidadRecaudadora, puntoDeVenta, electronicInvoice, fechaFactura);
            resetForm();
          }}
          color="primary"
          disabled={!coupons.length || !lastLineData.allow_payment}
        >
          Guardar cobranza
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ExternalPaymentTable = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [verificationData, setVerificationData] = useState(null);
  const [lastLineData, setLastLineData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { branch, uuid } = useSelector((state) => state.userLogged);

  const fileInputRef = useRef(null);

  const [paymentProvider, setPaymentProvider] = useState("XNET");
  console.log(paymentProvider);
  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleVerify = async () => {
    if (!selectedFile) {
      setOpenErrorDialog(true);
    } else {
      try {
        const response = await dispatch(
          verifyExternalPayment({
            file: selectedFile,
            provider: paymentProvider,
          })
        ).unwrap();
        setVerificationData(response.coupons);
        setLastLineData(response.last_line_data);
        setIsDialogOpen(true); // Abre el diálogo para mostrar la verificación
      } catch (error) {
        setApiResponse(
          error.message ||
          "Hubo un error al verificar el archivo. Por favor, intenta nuevamente."
        );
      }
    }
  };

  const handleConfirmPayment = async (entidadRecaudadora, puntoDeVenta, electronicInvoice, fechaFactura) => {

    // Obtenemos los valores de entidad recaudadora y punto de venta
    dispatch(sendExternalPaymentData({
      uuid_user: uuid,
      uuid_branch: branch[0].uuid_branch,
      entidadRecaudadora,
      puntoDeVenta,
      electronicInvoice,
      fechaFactura,
      file: selectedFile,
      provider: paymentProvider,
    }));

    // Cierra el diálogo y resetea los estados
    setIsDialogOpen(false);
    setVerificationData(null);
    setSelectedFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        ml: 3,
      }}
    >
      <Box
        sx={{
          mt: 3,
          justifyContent: "center",
          backgroundColor: "#f2f2f2",
          borderRadius: "20px",
          padding: "15px",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Cupon | Registrar | Cobros externos
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            mt: 2,
            ml: 0.5,
            display: "flex",
            alignItems: "center",
          }}
        >
          Proveedor cobro:
          <Select
            value={paymentProvider}
            onChange={(e) => setPaymentProvider(e.target.value)}
            sx={{ marginLeft: "8px", fontWeight: "bold" }}
          >
            <MenuItem value="XNET">XNET</MenuItem>
            <MenuItem value="ICBC">ICBC</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            mt: 2,
          }}
        >
          <input
            type="file"
            accept=".txt"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
            id="fileUpload"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="fileUpload">
              <Button
                variant="contained"
                component="span"
                sx={{
                  backgroundColor: "#5e894f",
                  borderRadius: "15px",
                  height: "50px",
                  width: "250px",
                  fontWeight: "bold",
                  fontSize: "100%",
                  boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
                }}
              >
                <FileUploadIcon />
                <Box
                  sx={{
                    fontSize: "15px",
                    ml: 1,
                  }}
                >
                  Seleccionar archivo
                </Box>
              </Button>
            </label>
            <Box
              sx={{
                fontSize: "15px",
                ml: 3,
              }}
            >
              {selectedFile ? selectedFile.name : "Sin archivos seleccionados"}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "2px",
              backgroundColor: "#ccc",
              my: 2,
            }}
          />
          <Button
            variant="outlined"
            component="span"
            onClick={handleVerify}
            sx={{
              borderColor: "#5e894f",
              color: "#5e894f",
              borderRadius: "15px",
              height: "50px",
              width: "250px",
              fontWeight: "bold",
              fontSize: "100%",
              boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
            }}
          >
            Generar Archivo
          </Button>
        </Box>
        <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Es necesario seleccionar un archivo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={!!apiResponse}
        onClose={() => setApiResponse(null)}
        aria-labelledby="api-response-dialog-title"
      >
        <DialogTitle id="api-response-dialog-title">
          {"Generación de cobros"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{apiResponse}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setApiResponse(null)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <CouponsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        coupons={verificationData || []}
        lastLineData={lastLineData || []}
        onConfirm={handleConfirmPayment}
      />
    </Box>
  );
};

export default ExternalPaymentTable;
